import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { Button } from 'components/buttons';
import { IconClose } from 'components/Icon';

const OverlayStyled = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: ${zIndex};

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding: ${({ theme }) => theme.spacing[12]};
    }
`;

const ContentWrapper = styled.div`
    max-width: 900px;
    width: 100%;

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        position: relative;
    }
`;

const CloseButtonStyled = styled(Button)`
    position: absolute;
    top: ${({ theme }) => theme.spacing[2]};
    right: ${({ theme }) => theme.spacing[3]};
    padding: ${({ theme }) => theme.spacing[2]};
    border-style: none;

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        top: ${({ theme }) => `-${theme.spacing['2.5']}`};
        right: ${({ theme }) => `-${theme.spacing['8']}`};
    }
`;

const IconStyled = styled(IconClose)`
    & svg {
        fill: ${({ theme }) => theme.palette.white};
    }
`;

export { OverlayStyled, ContentWrapper, CloseButtonStyled, IconStyled };
