import React, { Component } from 'react';
import ZIndexManager from 'components/ZIndexManager';
import VideoPlayer from 'components/VideoPlayer';
import { OverlayStyled, ContentWrapper, CloseButtonStyled, IconStyled } from './Lightbox.style';

class Lightbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLightboxOpen: false,
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    openLightbox() {
        this.setState((state) => ({
            ...state,
            isLightboxOpen: true,
        }));
    }

    closeLightbox() {
        this.setState((state) => ({
            ...state,
            isLightboxOpen: false,
        }));
    }

    renderLightbox() {
        const { video } = this.props;

        return (
            <ZIndexManager layer="lightbox">
                <OverlayStyled onClick={this.closeLightbox}>
                    <ContentWrapper>
                        <CloseButtonStyled variant="flat" onClick={this.closeLightbox}>
                            <IconStyled size="xlarge" />
                        </CloseButtonStyled>
                        <VideoPlayer url={video} title="Why Housesitting Works" />
                    </ContentWrapper>
                </OverlayStyled>
            </ZIndexManager>
        );
    }

    render() {
        const { isLightboxOpen } = this.state;
        const { children } = this.props;

        return (
            <>
                {children({
                    openLightbox: this.openLightbox,
                    closeLightbox: this.closeLightbox,
                })}
                {isLightboxOpen && this.renderLightbox()}
            </>
        );
    }
}

export default Lightbox;
